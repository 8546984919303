<template>
  <div class="header" :class="isHeadBg||current=='index' ? 'header-bg' : ''">
  
    <!-- 菜单 -->
    <div class="ui header-wrap">
      <img class="logo" src="@/assets/images/footer-logo.png" alt="" />
      <unordered-list-outlined class="menu-icon" @click="menuAction" />
      <div class="f1">
        <div class="menu" v-if="device !== 'mobile' || isOpenMenu">
          <div
            class="menu-item"
            v-for="item in menuList"
            :key="item.path"
            :class="current == item.path ? 'menu-active' : ''"
            @click="menuClick(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { UnorderedListOutlined } from "@ant-design/icons-vue";
// import { message } from 'ant-design-vue';

export default defineComponent({
  components: { UnorderedListOutlined },
  props: {
    menuKey: String,
    topY: Number,
  },
  setup(props) {
    const router = useRouter();

    // 当前中的菜单
    const current = ref([props.menuKey]);

    let isHeadBg = ref(false);

    // 监听页面滚动
    window.addEventListener("scroll", () => {
      isHeadBg.value = window.scrollY > (props.topY ? props.topY : 60) ? true : false;
    });

    let isOpenMenu = ref(false);

    // PC端显示菜单
    const device = inject("device") || "";

    const menuAction = () => {
      isOpenMenu.value = !isOpenMenu.value;
    };

    const menuList = [
      { name: "首页", path: "index" },
      { name: "API服务产品", path: "product" },
      { name: "实我核验产品", path: "authpro" },
      { name: "解决方案", path: "plan" },
      { name: "新闻资讯", path: "news" },
      { name: "关于我们", path: "about" },
    ];
    // 搜索关键词
    const searchValue = ref("");

    // 菜单切换
    const menuClick = (item) => {
      router.push({ path: "/" + item.path });
    };

    return {
      current,
      searchValue,
      menuList,
      menuClick,
      isHeadBg,
      menuAction,
      isOpenMenu,
      device,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  transition: 0.5s;
  &.header-bg {
    background: url("../assets/images/menu-bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .menu-icon {
    display: none;
  }
  .header-wrap {
    align-items: center;
    padding: 15px 0 15px;
    width: 1200px;
    margin: 0 auto;
    .logo {
      width: 187px;
      height: 42px;
    }
    .menu {
      display: flex;
      color: #fff;
      font-size: 15px;
      .menu-item {
        cursor: pointer;
        position: relative;
        margin-left: 93px;
        &::after {
          content: "";
          display: block;
          width: 18px;
          height: 3px;
          background-color: transparent;
          position: absolute;
          bottom: -10px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          &::after {
            background-color: #fff;
          }
        }
        &.menu-active {
          font-weight: 600;
          &::after {
            background-color: #fff;
          }
        }
      }
    }
  }
}
.logo-wrap {
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  padding: 20px 0;
  :deep(.ant-input) {
    background: #f7f7f7;
    border: 1px solid #ececec;
    width: 350px;
  }
  .header-logo {
    width: 224px;
  }
}
</style>

<style lang="less" scoped>
.mobile {
  .header {
    padding-left: 10px;
    .menu-icon {
      display: block;
      font-size: 25px;
      color: #5aa1ff;
      position: absolute;
      right: 10px;
    }
    .header-wrap {
      width: 100%;
      padding: 10px 0 12px;
      .logo {
        width: 160px;
        height: 38px;
      }
      .menu {
        display: block;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        background: rgba(4, 2, 21, 0.9);

        .menu-item {
          margin: 10px 0;
          padding: 0 20px;
          &::after {
            display: none;
          }
          &.menu-active {
            background-color: #1a4fba;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}
</style>
