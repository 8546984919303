<template>
  <div class="user-wrap" :class="screenWidth<992?'':'container'">
    <div class="title">{{title}}</div>
    <div class="info-main" v-if="title=='中码服务协议'">
      <div class="info-title strong">特别提示</div>
      <div class="strong">感谢您选择中码。您在接受中码安全服务前，除非您已充分阅读，并确认同意本协议所有条款，否则您将无权获得、部署、使用中码提供的相关服务。当您完成“同意条款并注册”或开始以注册、信息提交、部署及使用等其他行为开始使用中码相关服务，均视为您已阅读、充分理解本协议的约定，同意接受本协议的约束，包括中码对本协议随时作出的更新修改、补充公告及其他方式的变更等。</div>
      <div class="strong">中码提示您，在您阅读本条款时，本协议已对免除或限制责任的条款进行了加粗特别提示您注意。</div>
      <div>如您对本协议有任何疑问或对本协议不能做出合理理解，您可通过平台或中码官网【http://zhongmatech.com/】联系我们进行咨询。</div>
      <div>
        <span class="strong">如涉及具体服务的服务内容、权利义务等内容的表述,参加具体服务页面或相关具体协议，以具体服务页面或相关具体协议为准，</span>
        本协议条款前的标题或关键词系对项下条款的主旨表达之义，利于您对该条款的理解，但不影响或限制协议条款依据法律所作出的真实的含义或解释。
      </div>

      <div class="info-title strong">协议主体及范围</div>
      <div>
        协议主体指本协议双方，即同意接受本协议全部条款及使用中码服务的“您”和提供中码服务的“中码”。“您”在本协议项下亦可能被称呼为“用户”。本协议由双方共同缔结，对双方均具有法律约束力。
        <span class="strong">请确保您是具备完全民事权利能力和完全民事行为能力的自然人,法人或其他组织，如您不具备上述要求，您将不能正常使用中码服务。</span>
      </div>
      <div class="strong">根据国家法律法规变化、运营需要或为提升中码服务体验为目的，中码将在必要时对本协议作出相关公告、使用规则、风险提示、政策、规范等内容以及不时的修改更新，上述内容一经正式发布，构成本协议不可分割的组成部分，与本协议具有同等法律效力，共同适用于您使用中码服务。如您在上述内容公布或更新修改后继续适用中码服务，将视为您已同意上述内容，即本协议包括为本协议作出的相关公告、使用规则、风险提示、政策、规范等内容。</div>

      <div class="info-title strong">账号</div>
      <div>1. 您必须是具备完全民事权利能力和完全民事行为能力的自然人,法人或其他组织，按照中码的要求完成注册以获得中码服务账号（以下简称“账号”）。</div>
      <div>2. 您的账号信息应不违反国家相关法律法规，并保证填写提交的信息真实、合法、有效。如相关信息发生变更，您应及时完成更新。</div>
      <div>3. 您的账号通常会成为您在使用中码服务过程中的唯一身份标识，请妥善保管好您注册的账号和设置的密码，如发现账号异常，请立即通知中码。</div>
      <div>4. 请保证在使用中码服务过程中，遵守国家法律法规及电信网络安全的相关政策性规范和用户使用规范。</div>

      <div class="info-title strong">用户使用规范</div>
      <div>· 用户在使用中码提供的服务过程中，不得制作、复制、发布或传播违反法律法规禁止或侵犯第三方合法权益的信息，信息包括但不限于：</div>
      <div>· 煽动抗拒、破坏宪法和法律、行政法规实施的；</div>
      <div>· 煽动颠覆国家政权，危害国家安全，泄露国家秘密，破坏国家统一的；</div>
      <div>· 煽动民族仇恨、民族歧视，破坏民族团结的，损害国家荣誉和利益的；</div>
      <div>· 破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div>· 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
      <div>· 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
      <div>· 侮辱或者诽谤他人，侵害他人合法权益的；</div>
      <div>· 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</div>
      <div>· 侵犯国家利益、社会公共利益及第三人利益的；</div>
      <div>· 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</div>
      <div>· 涉及他人隐私、个人信息或资料的；</div>
      <div>· 其他违反宪法和法律、行政法规的。</div>

      <div>用户对在接受服务过程中，所制作、复制、发布或传播的上述信息自行承当相应的违法或侵权法律后果。</div>
      <div>中码有义务为国家或第三方存储、保存和提交相应的违法或侵权信息证据。</div>
      <div>用户在接受中码服务时，必须遵守相关法律、行政法规及规范性政策，不得从事下列违法或不正当行为：</div>
      <div>· 删除服务上一切关于著作权的信息；</div>
      <div>· 对相关服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现中码的源代码；</div>
      <div>· 修改、破坏中码服务原状；</div>
      <div>· 进行任何危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众网络或者他人操作系统并删除、修改、增加、复制存储信息；</div>
      <div>· 未经许可企图探查、扫描、测试中码服务系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；</div>
      <div>· 未经中码书面同意，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、借助中码的服务发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；</div>
      <div>· 通过修改或伪造服务运行中的指令、数据、数据包，增加、删减、变动服务的功能或运行效果，或将用于上述用途的服务通过信息网络及其他方式向公众传播或者运营；</div>
      <div>· 在未经中码书面明确授权的前提下，出售、出租、出借、散布、转移或转授权服务或相关的链接或从使用服务或服务的条款中获利；</div>
      <div>· 其他以任何不合法的方式、或为任何非法目的、或以任何与本协议不一致的方式使用中码提供的服务；</div>
      <div>· 其他法律法规禁止或未经中码书面授权的行为。</div>
      <div>用户若违反上述规定，中码有权采取终止、完全或部分中止、限制用户使用中码服务。如因用户上述行为，导致或产生第三方主张的任何索赔、要求或损失，由用户自行承担；由此造成中码损失的，中码有权追偿。</div>

      <div class="info-title strong">服务内容</div>
      <div>1. 中码服务是指通过中码提供的各类服务，您可以通过中码官网（http://zhongmatech.com/）的具体服务页面获得相应服务内容。</div>
      <div class="strong">2. 您在接受服务过程中，因网站遭受如DDoS攻击等不可抗力因素时，会严重占用中码流量，并在攻击到达一定的程度时，中码的服务会出现无法加载等异常现象。届时，中码将会提供相关的付费定制防护服务来升级产品，如果用户不愿使用相关付费服务，中码有权终止所有服务。</div>
      <div>3. 您应知晓并同意，中码可能会根据需要更新或调整中码服务的内容，您也可以通过中码官网【http://zhongmatech.com/】了解最近的服务。</div>
      <div>4. 您使用中码提供的服务，您需对拟部署中码服务的网站和APP享有所有权或合法的使用权，否则，中码有权终止您的全部验证服务。如因此侵犯第三人合法权益的，由您承担全部法律责任。</div>

      <div class="info-title">服务说明</div>
      <div>1. 您确认，您接受本协议并同意使用中码服务系您真实意思表示，非因本协议之目的或其他任何非法目的或侵害第三人利益使用本协议项下中码服务。否则，由您依照法律法规承担因此而导致的一切后果。</div>
      <div>2. 中码为互联网服务的提供方，在您使用服务过程中，您需具备相关软件运行或接受服务所需要的软硬件条件，以避免互联网过程中存在的某种使用风险。在现行法律允许范围内，中码不对该等使用风险所遭受的直接或间接侵权损失承担赔偿责任。同时，您还必须承担使用服务所需要的网络、设备等费用。</div>

      <div class="info-title">信息保护</div>
      <div>1. 中码重视用户个人信息的保护，并采取合理措施保护用户的信息和数据。在您接收本协议项下的服务时，您需注册提交相关信息，中码会按照公布的隐私政策收集、存储、使用和保护您的个人信息。</div>
      <div>2. 中码的服务不含有任何旨在破坏用户计算机数据和获取用户隐私信息的恶意代码。未经您的同意，中码不会向任何第三方公开、披露您的个人信息，也不会与第三方进行共享，但法律法规另有规定的除外。</div>
      <div>3. 您可以通过认真阅读《法律声明及隐私政策》，进一步的了解您个人信息的处理方式，帮助您更好的保护您的隐私。</div>

      <div class="info-title">知识产权</div>
      <div>1. 中码对其提供的服务享有完整的所有权、知识产权或得到充分授权。您可以为非商业目的在计算机或其他掌上终端上安装、使用、显示、运行或接受中码服务。在您的使用服务过程中，不因本协议发生任何中码服务的知识产权权利转移。</div>
      <div>2. 因本协议向您提供的服务中所有信息内容的所有权及知识产权权利人为中码。该信息内容包括但不限于程序代码、图形、图片、账号、图标、文字、界面设计、数据资料等，除按照法律法规规定由相关权利人享有权利的内容除外，但用户经许可自行提供的图形、鱼片、图标等资料除外。</div>
      <div>3. 用户不得为商业运营目的安装、使用、运行中码提供的免费版服务，不可以对使用过程中释放到任何终端内存中的数据及本软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入相关系统。</div>
      
      <div class="info-title">免责声明</div>
      <div>1. 用户应在使用中码产品及服务过程中，应遵守国家法律、法规及政策的规定，自行承担因违法信息、违法行为或其他侵权行为所产生的法律后果。</div>
      <div>2. 中码对所有用户在行为验证产品中上载的图片、图形或其他数字化作品的著作权归属不负事先审查义务。用户应当在明确自己没有侵害他人著作权的前提之下，在中码行为验证产品上载数字化作品，否则应当自行承担有关法律责任。</div>
      <div>3. 鉴于本协议项下的产品和服务系基于互联网服务完成，网络服务包括但不限于网络的稳定性问题、恶意的网络攻击行为、运营商服务无法控制等因素，中码可能变更、中断、中止或终止中码行为验证服务。如发生该等情形，中码免于承担责任，但尽可能的及时通过网页公告、系统通知、短信提醒等其他合理方式告知您。</div>
      <div>4. 中码提供的服务中不带任何病毒或恶意代码、程序，您对自行部署相关服务代码的行为负责。如因您拟部署的网站/APP的设备、部署环境或您的部署行为原因导致的相应风险，由您自行承担，中码对该等事项不承担任何责任。</div>
      
      <div class="info-title">保密</div>
      <div>本协议任何一方对从另一方获得的财务状况、知识产权、商业模式、客户信息、商业秘密等事项或资料均负有保密义务；除对履行本协议而必须知道上述保密事项或资料的人员外，不得向任何人或实体透露保密事项或资料。</div>
      
      <div class="info-title">违约责任</div>
      <div>1. 用户违反本协议任一条款约定，均视为违约。中码有权选择采取终止、完全或部分中止、限制用户使用中码服务。</div>
      <div>2. 用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失赔偿或行政处罚等，由用户独立承担责任。由此导致中码遭受损失的，用户应当予以赔偿。</div>
      <div>3. 中码拥有本协议项下服务的相关知识产权，该权利受《中华人民共和国著作权法》、《计算机软件保护条例》等中国法律、法规及相关国际公约的保护，任何未经授权而擅自修改、复制、发行、出租、传播、翻译、反向编译或反向工程本软件程序的全部或部分，均属于侵权行为，中码保留依法追究其法律责任的权利。</div>
      
      <div class="info-title">协议修改</div>
      <div>1. 中码有权随时修订本协议的任何条款，一旦本协议的内容发生变动，中码将会在官网上公布修订后的协议内容。同时，中码也可选择其他合理方式（比如系统通知、公告等）向用户通知修订内容。</div>
      <div>2. 用户不同意中码对本协议的修订内容，应自修改事项确定生效之日起停止注册、部署、使用中码服务的行为。用户继续接受中码服务的，则视为用户同意对本协议相关条款所做的修订。</div>
      
      <div class="info-title">通知送达</div>
      <div>中码如提示某项服务收费或发布使用规则或风险告知或协议修订或系统升级等相关事项，将在用户使用的网页界面或服务平台或软件操作界面发布有关事项的公告，自公告发布之日起，各类通知、修订事项生效。届时，用户在选择接受服务时，视为已通知送达用户，中码无需另行通知。</div>
      
      <div class="info-title">法律适用及管辖</div>
      <div>本协议的成立、生效、履行、修订、补充、解释及纠纷解决，均适用中华人民共和国大陆地区法律，争议管辖地为本协议签订地有管辖权的人民法院。</div>

      <div class="info-title">其他约定</div>
      <div>1. 中码有可能针对本协议某项服务添加相关特别约定或服务协议。届时，用户接受该项服务的前提为同意该项服务所附带的所有规范性文件，包括但不限于本服务使用协议及专项服务协议等。</div>
      <div>2. 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定之外，未赋予本协议各方其他权利。</div>
      <div>3. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有约束力，本协议的其余条款仍应有效，对双方具有约束力。</div>
      <div>4. 本协议签订地为中华人民共和国湖北省武汉市东湖新技术开发区。</div>

      <div class="text-right">中码</div>
      <div class="text-right">中码科技发展（成都）有限公司</div>
    </div>

    <div class="info-main" v-else>
      <div class="info-title strong">中码科技 隐私政策</div>
      <div>欢迎使用 中码科技的产品。在本文中我们会用“中码”、“我们”、“我方”或“我们的”来指代 中码科技发展（成都）有限公司。</div>
      <div>我们非常重视用户的隐私。本文将说明我们收集和使用您的哪些个人数据、为什么要收集以及如何保护这些数据。</div>
      <div>如果您是 中码科技的用户，请仔细阅读本隐私政策，以便详细了解我们如何使用您的个人数据。</div>

      <div class="info-title strong">1. 我们在保护您的隐私方面所承担的责任</div>
      <div>如果您是 中码科技 的客户或用户，则本隐私政策适用于您。我们将说明在您使用 中码科技 时会发生的情况，包括我们如何使用您与 中码科技 之间的交互。我们还会说明您享有的权利。 中码科技将负责管理您的个人数据。这意味着我们有责任确定如何及为何使用您的个人数据。</div>
      
      <div class="info-title strong">2. 您的责任</div>
      <div>· 阅读本隐私政策</div>
      <div>· 另外，请阅读您与中码签订的任何合同（通常称为“最终用户许可协议”或“使用条款”）</div>
      <div>如果您向我们提供他人的信息或者他人向我们提供您的信息，我们将仅出于向我们提供之时的特定原因而使用这些信息。提交这些信息，即表明您确认自己有权授权我们按本隐私政策中所述的内容来代表您处理这些信息。</div>
      
      <div class="info-title strong">3. 您提供给我们的信息</div>
      <div>我们的一些应用程序或服务会要求您创建帐户：</div>
      <div>· 加入中码科技会员</div>
      <div>可通过移动设备小程序注册实我认证账户。要注册实我帐户，您需要提供个人身份证号码及手机并自行设置密码。</div>

      <div class="info-title strong">4. 我们收集的信息</div>
      <div class="strong">关于您如何使用 中码科技 的信息</div>
      <div>· 您的设备 ID、IP 地址、登录信息、浏览器类型和版本、时区设定、域名、浏览器插件类型、您可能所在位置的地理位置信息、操作系统和版本。</div>
      <div>· 您的使用模式、您查看并与之交互的内容（包括任何广告），其中包括您在设备上使用服务和应用程序时涉及的个人数据。我们之所以收集这些信息，是为了根据您的具体需求提供个性化服务。</div>
      <div>· 设备和应用程序设定、错误和硬件活动</div>
      <div>· 设备信息和服务、产品或服务器日志（包含技术数据，其中可能包括有关您对我们服务、产品或网站的使用情况的个人数据）。</div>
      <div class="strong">我们收集的社交网络相关个人数据</div>
      <div>如果您使用我们的任何社交网络功能、页面或插件，或使用我们支持与社交网络交互的产品或服务，则我们可能会接收此类使用情形涉及的个人数据。例如：</div>
      <div>· 如果您使用社交网络帐户登录我们的网站、应用程序或服务，我们将会从您社交网络的个人资料中获取基本详细信息。我们获取的基本详细信息可能取决于个人社交网络帐户的隐私设定。通常，这些信息包括您的社交网络 ID 和名称。仅在您授予我们访问权限时，我们才会从您的个人资料中获取更多个人数据。</div>
      <div>请阅读您所用社交网络的隐私政策，以了解该社交网络将如何使用您的信息。</div>
      <div class="strong">关于敏感信息的规定</div>
      <div>除非征得您的明确同意或者相关法律有特定要求，否则我们不会收集您的任何“敏感”信息。“敏感”信息是指诸如以下所列的信息：种族或民族、政治观点、宗教/哲学信仰、工会身份、遗传信息、生物特征信息、健康状况信息、性生活或性取向信息或有关罪行或涉嫌罪行、财务帐户及个人行踪的信息。</div>
      
      <div class="info-title strong">5. 中码如何使用收集的信息</div>
      <div>我们仅出于正当理由使用您的信息。这称为“信息处理的法律依据”。有时，我们可能会请求您的授权来执行一些操作，例如，我们会询问您是否想要使用 中码科技 服务。</div>
      <div>仅当法律规定我们可以未经授权使用您的信息且这样做符合您所享有的权利时，我们才会未经您授权使用您的信息。</div>
      <div>我们出于以下原因使用您的信息：</div>
      <div>A. 确保 中码科技 正常运行</div>
      <div>· 当我们出于向您提供产品和服务的目的而使用个人数据时，我们将按照您与 中码科技 之间签订的合同行事。我们可能会使用所收集的信息（包括个人数据）来执行以下操作：</div>
      <div>· 向您提供所请求的产品或服务，包括任何备份和恢复服务；</div>
      <div>· 处理您在我们产品中进行的注册或创建帐户；</div>
      <div>· 向您提供所需的通知和软件更新，其中可能包含重要的安全和功能补丁，这些补丁有助于提升设备安全性和保护设备中的个人数据；</div>
      <div>· 以及发送重要信息（包括通过短信、推送通知），涉及适用使用条款的重要变更，和/或为履行我们的法律和合同义务而可能需要进行的其他通信或通知。</div>
      <div>法律依据：合同</div>
      <div>B. 改进 中码科技</div>
      <div>我们不断努力提升产品和服务的质量。我们努力确保产品和服务的质量、不断完善身份管理，并加强网络和信息安全。为改进产品、提供软件更新、管理缺陷和崩溃问题，中码科技 会获取您的授权以访问您设备中的相关数据。我们能够分析软件错误和硬件问题，从而改进产品和服务，为您提供最佳的 中码科技 体验。我们访问的数据包括您的设备ID、设备和应用程序设定、错误和硬件活动。我们使用您的 IP 地址来确定您的大致位置，并使用您的本地语言为您提供信息。</div>
      <div>此类产品改进/完善工作在整体而不是单个用户层面进行，因此并不针对特定用户。</div>
      <div>请注意，我们会通过访问您手机上的某些日志数据，自动对您手机的技术错误进行一些分析，这是我们从技术角度改进 中码科技 所享有的合法权益。</div>
      <div>法律依据：同意</div>
      <div>C. 防欺诈调查和合规性</div>
      <div>· 中码科技 可能会使用个人数据来防止欺诈行为，以及对违反我们政策的行为进行调查。我们可能会出于适用法律、法规及法院指令的要求而使用个人数据，并遵从这些机构提出的有效法律信息请求。我们可能会使用您的个人数据来行使或维护任何中码关联公司的合法权利，或执行任何中码产品或服务使用条款。</div>
      <div>法律依据：法律义务、合同</div>
      <div>D. 其他用途</div>
      <div>· 我们可能会将您的个人数据用于其他用途：</div>
      <div>· 关联或整合我们从上述不同来源收集的个人数据（包括从其他中码关联公司接收的、与您使用其他中码集团产品和服务相关的个人数据）。个人数据可能会通过帐号等唯一标识符进行关联。此外，我们亦可能决定将两个或多个数据库合并成单个客户信息数据库。为了您和/或我方的便利（例如，方便您注册新的服务），以及为了向您提供更优质的个性化服务及内容，我们可能会关联或整合个人数据。</div>
      <div>法律依据：同意、合同、法律义务</div>
      <div>以下是这些“法律依据”中各项的含义：</div>
      <div class="strong">同意</div>
      <div>您已明确同意我们可以出于特定目的处理您的个人数据。</div>
      <div class="strong">合同</div>
      <div>为了执行您与我们签订的合同，或者因为我们已要求您在签订合同前执行特定程序，而必须处理您的数据。</div>
      <div class="strong">法律义务</div>
      <div>我们有义务按照适用的法律和法规处理您的数据。</div>

      <div class="info-title strong">6. 共享您的相关数据</div>
      <div class="strong">中码进行的数据共享</div>
      <div>一般而言，中码不会在未经您同意的情况下向第三方出售、租赁或以其他方式披露您的个人数据。但是，也存在一些例外情况：</div>
      <div class="strong">其他中码关联公司</div>
      <div>在以下情况下，我们可能会向其他中码关联公司披露您的个人数据（包括相关产品或服务的使用情况信息，以及您不时提供的任何客户偏好信息）：</div>
      <div>· 您使用其他中码关联公司的产品或服务，并曾与其共享个人数据。在此类情况下，我们和其他相关中码关联公司可能会整合所有与您有关的中码数据，并根据我们各自的隐私政策使用这些数据。</div>
      <div>· 我们已让涉及的其他中码关联公司为我们提供数据处理服务（并且这些其他中码关联公司只会根据我们的指示处理您的个人数据，并承担相应的保密和安全义务）；</div>
      <div>· 您使用 中码科技 设备和/或应用程序或服务访问、购买或下载由其他中码关联公司发布、开发、支持或提供的内容、服务、应用程序或产品。这些公司可能会将此类个人数据与他们保留的、与您有关的其他详细信息进行整合。除非他们向您提供自己的隐私政策，否则，他们可以将您的个人详细信息用于本隐私政策中指定的用途。如果您在不同的中码关联公司中拥有多个帐户，则这些中码关联公司也可能会依照各自的隐私政策与我们共享个人数据；</div>
      <div>· 中码将我们的部分或全部业务出售或转移给另一家中码关联公司</div>
      <div class="strong">我们的服务提供商</div>
      <div>我们经常使用第三方服务提供商和其他中码关联公司来为我们提供服务，例如用于以下用途的服务：托管应用程序、执行数据分析、提供帐户管理和备份服务。在此类情形下，我们有时必须与这些服务提供商和其他中码关联公司共享我们拥有的数据，以使这些服务正常运行。只有在确实必要的情况下，我们才会共享您的个人数据。我们确保这些服务提供商和其他中码关联公司采用本隐私政策中详细规定的相同保护措施来保护您的个人数据。</div>
      <div class="strong">第三方广告合作伙伴</div>
      <div>当您下载和/或使用任何包含第三方广告（无论是在应用程序中或服务本身，还是通过框架、包装器、弹出窗口或任何其他形式的广告功能显示给最终用户）的 中码科技 应用程序和/或服务时，我们可能会与广告合作伙伴共享我们收集的、与您使用应用程序和服务相关的个人数据，以便他们更好地定位和/或个性化在您所用的任何 中码科技 服务或应用程序中显示的广告。由于我们的一些第三方广告合作伙伴独立行事，他们将根据各自的隐私政策处理共享数据。当进行此类共享时，我们的应用程序和/或服务将在您首次激活这些应用程序和/或服务时通知您。</div>
      <div class="strong">法律及商业用途</div>
      <div>我们也可能向下列实体披露我们收集的信息：</div>
      <div>政府机构和执法机构，以防止欺诈、遵守法律及满足此类机构的合理请求；</div>
      <div>第三方（包括专业顾问），以行使或捍卫任何中码关联公司的合法权利或任何中码产品或服务的使用条款；</div>
      <div>第三方买方或卖方以及他们和我方的专业顾问（在涉及诸如合并、业务收购或破产等公司事务时）。</div>
      <div class="strong">统计信息和一般信息</div>
      <div>我们出于上述多种目的准备、汇总信息（包括“一般”统计信息）或对此类信息进行去标识化处理。我们认为无法通过此类信息有效识别您的身份，因此我们可能会与任何第三方（如我们的合作伙伴、广告商、行业机构、媒体和/或公众）或任何其他中码关联公司共享此类信息。</div>
      
      <div class="info-title strong">7. 在国际范围传输您的信息</div>
      <div>中码是中码集团旗下的公司，中码集团是一家全球性的组织。出于本政策中所述的目的，您的信息可能会被传输到其他国家/地区的中码关联公司（包括代表其进行业务运营的服务提供商）和其他第三方，这些国家/地区的数据保护法律与您所在国家/地区的数据保护法律不一定具有同等级别。</div>
      <div>请注意，我们的一些服务器位于日本和欧洲。国际社会已公认日本可提供与 EEA 同等级别的数据保护能力。</div>
      <div>中码使用各种法律机制来保障您享有的个人信息权利，并提供相应的保护。正因此，中码关联公司（及其授权的数据处理公司）都会遵守我们关于处理个人数据的内部政策，该政策符合公平处理及确保充分保护个人数据的主要数据保护原则。</div>
      <div>如果您想获得更多关于国际数据传输的信息，请联系我们。</div>
      
      <div class="info-title strong">8. 营销沟通</div>
      <div>中码科技 的所有营销活动均由当地中码办事处负责。您当地的中码办事处会根据您关于从中码电子业务部门获取市场营销资料的偏好来为您提供支持。</div>
      <div>请注意，我们可能会偶尔向您发送有关您当前使用或曾经使用的 中码科技 产品和服务的重要信息（如产品安全公告和服务变更），或有关您帐户的重要信息。这些通知不会受您的营销沟通偏好的影响。</div>
      
      <div class="info-title strong">9. 个人信息的安全性</div>
      <div class="strong">我们如何保护您的信息</div>
      <div>我们采取合理的安全措施（即一系列步骤）来防止您的信息被意外或非法销毁、丢失、变更、未经授权披露或访问，包括在适当情况下：</div>
      <div>· 在收集或传输敏感信息时使用安全套接字层 （SSL） 加密（SSL 加密功能用于确保信息仅由我方人员读取）</div>
      <div>· 就我们收集的、您的相关信息提供访问限制（例如，只允许我们的工作人员出于开展我们业务活动的目的进行访问）</div>
      <div>· 实施符合行业标准的物理、电子和程序化保护措施</div>
      <div class="strong">您应如何保护自己的信息</div>
      <div>对于使用互联网的个人，我们建议在使用用户帐户时，一定要格外谨慎，并遵守下面的一些基本规则：</div>
      <div>· 不要使用过于简单的密码（例如单一的字典单词）</div>
      <div>· 多个帐户不要使用同一个密码</div>
      <div>· 使用长密码（至少包含 10 个字符；但是建议使用更多字符）</div>
      <div>· 使用由大小写字母、数字及特殊字符（例如 $%^& 等）组合而成的密码</div>
      <div>· 妥善保管密码（切勿将其写下来或与他人共用）并定期更改密码</div>
      <div>如果您认为自己的隐私遭到侵犯，请立即联系我们。</div>

      <div class="info-title strong">10. 个人信息的访问与权利行使</div>
      <div>若要获取我们存储的您的个人数据副本，或想要行使以下任何权利，请联系我们。我们可能会要求您提供一些身份证明信息，以便我们确认请求是由您发出的。</div>
      <div>您有权根据适用的法律和法规访问、更正、补充、删除您的个人数据或注销您的帐户。您可以联系我们了解您享有的权利。</div>
      <div>您还有投诉的权利。如果您担心我们不尊重您的合法权利或不遵守适用的隐私法规，您可以联系您当地的国家数据保护机构或任何其他数据保护机构。</div>

      <div class="info-title strong">11. 我们将您的个人数据保留多久</div>
      <div>我们将仅根据我们的内部保留政策或出于上述“中码如何使用收集的信息”部分中所述的各种合理目的，以及因遵守任何适用法律和法规（关于强制保留与我们的客户和/或与客户进行的任何商业交易有关的某些类型的个人数据）而保留您的个人数据。如果出于以上“中码如何使用收集的信息”部分中所述任何目的，不再需要使用我们保有的任何个人数据，则我们承诺在一段合理时间后销毁这些个人数据或对这些数据进行去标识化处理。如果您担心我们不尊重您的合法权利或不遵守适用的隐私法规，您可以联系我们或您当地的数据保护机构。</div>

      <div class="info-title strong">12. 指向其他网站的链接</div>
      <div>我们的一些网站可能包含指向其他不由中码运营的网站（包括其他中码关联公司运营的网站）的链接。</div>
      <div>虽然中码尽量只链接到与中码秉持同等标准且尊重隐私的网站，但对于这些其他网站的内容、安全性或隐私实践，我们不承担任何责任。您应查看这些网站上显示的隐私及 Cookie 政策，以了解您的个人数据可能被如何使用。</div>

      <div class="info-title strong">13. 儿童隐私</div>
      <div>中码将 16 岁以下的个人视为儿童。我们不会在未经父母或监护人同意的情况下刻意搜集或收集儿童的个人数据。</div>
      <div>如果中码发觉在未经父母或监护人同意的情况下接收了儿童的相关个人数据，中码将竭尽所能采取下列措施：</div>
      <div>· 尽快从文件中删除此类个人数据；</div>
      <div>· 当无法进行删除时，确保此类个人数据不会被进一步用于任何目的，亦不会进一步披露给任何第三方</div>
      <div>任何家长或监护人，就我们对儿童个人数据的处理方式如存有任何疑问，请联系我们。</div>

      <div class="info-title strong">14. 变更</div>
      <div>中码保留对本隐私政策作出变更的权利。 本隐私政策可能会因任何原因而不时更新。本隐私政策如有任何变更，我们将通过 中码科技 发出通知或征求您的同意（如适用法律要求）。</div>
      
      <div class="info-title strong">15. 联系我们</div>
      <div>您可通过以下方式联系我们。在收到您的请求后，我们会在 15 个工作日内作出回应：</div>
      <div>服务热线：13649370931</div>
      <div>服务时间：9:00~21:00（星期一至星期五）</div>
      <div>电子邮件：marketing@zmkjcd.onaliyun.com</div>
    </div>
  </div>
</template>

<script>
import { defineComponent,ref,watch } from 'vue';
import { useWindowSize } from "@vueuse/core";

export default defineComponent({
  props:{
    modalShowTitle:String
  },
  setup(props) {

    let title = ref(props.modalShowTitle);

    watch(()=>props.modalShowTitle,(val)=>{
        title.value = val;
      },
    )

    const { width } = useWindowSize();

    let screenWidth = width;

    return {
      title,
      screenWidth
    };
  },

});
</script>

<style lang="less">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    height: calc(100vh - 110px);
    overflow-y: auto;
  }
  .user-wrap{
    .title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }
    .info-main{
      font-size: 16px;
      text-indent: 2em;
      line-height: 2;
      >div{
        margin-bottom: 10px;
      }
    }
    .info-title{
      text-indent: 0;
      margin-top: 20px;
    }
    .strong{
      font-weight: 600;
      color: #000;
    }
    .text-right{
      text-align: right;
    }
  }
}
</style>
