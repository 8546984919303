<template>
  <div class="outer-link">
    <a href="http://xh.21csp.com.cn/c83/" target="_blank">中国安全防范产品行业网站</a>
    <a href="http://www.chengdu.gov.cn/" target="_blank">成都市人民政府</a>
    <a href="http://cdgaj.chengdu.gov.cn/" target="_blank">成都市公安局</a>
    <a href="https://www.chengdurail.com" target="_blank">成都轨道集团官方网站</a>
    <a href="https://m.boe.com/" target="_blank">BOE（京东方）-全球创新型物联网企业</a>
    <a href="http://www.foxconn.com.cn/" target="_blank">富士康科技集团</a>
  </div>
</template>

<style lang="less" scoped>
.outer-link{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  a{
    display: inline-block;
    border: 1px solid rgba(130, 153, 245, .2);
    color: #99A8C1;
    padding: 5px 10px;
    &:hover{
      border: 1px solid rgba(130, 153, 245, .6);
    }
  }
}
.mobile{
  .outer-link{
    margin-bottom: 20px;
    a{
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 10px;
    }
  }
}
</style>